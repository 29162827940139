import { createStore } from 'vuex'

import modules from './modules'

const store = createStore({
  modules, 
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  // state : {
  //   plate: '',
  // },
  // mutations : {
  //   setPlate(state, plate) {
  //       state.plate = plate;
  //   },
  // },
  // actions : {
  //   updatePlate({ commit }, plate) {
  //       commit('setPlate', plate);
  //   },
  // },
  // getters :  {
  //   getPlate: (state) => state.plate,
  
  // },
})

export default store

