
export default [

  {
    path: "/Mod_NS_Configuration",
    name: "configuracion",
    meta: {
      title: "configuración",
      authRequired: true,
    },
    component: () => import("../views/pages/coming-soon.vue"),
  },  
  {  
    path: "/Mod_NS_Configuration/ns_ConRouter",
    name: "GestionRutas",
    meta: {
      title: "Rutas",
      requiresAuth: true,
    },
    component: () => import( "@/views/configuration/routes/index.vue" ),
  },
  {  
    path: "/Mod_NS_Configuration/ns_Typification",
    name: "GestionListas",
    meta: {
      title: "Listas Especiales",
      requiresAuth: true,
    },
    component: () => import( "@/views/configuration/typifications/index.vue" ),
  },
  {
    path: "/Mod_NS_Travel",
    name: "viajes",
    meta: {
      title: "viajes",
      authRequired: true,
    },
    component: () => import("../views/pages/coming-soon.vue"),
  },  
  
  {  
    path: "/Mod_NS_Travel/ns_MgmtTravel",
    name: "GestionViajes",
    meta: {
      title: "Viajes",
      requiresAuth: true,
    },
    component: () => import( "@/views/travel/index.vue" ),
  },

  {  
    path: "/Mod_NS_Travel/ns_FollowUpTravel",
    name: "SeguimientoViajes",
    meta: {
      title: "Seguimiento de Viajes",
      requiresAuth: true,
    },
    component: () => import( "@/views/travel/followUp.vue" ),
  },

  {  
    path: "/Mod_Utility/ut_linkAdvance",
    name: "VinculacionEspecialAnticipos",
    meta: {
      title: "Vinculación Especial de Anticipos",
      requiresAuth: true,
    },
    component: () => import( "@/views/utility/linkAdvance/index.vue" ),
  },

  {
    path: "/test",
    name: "test",
    meta: {
      title: "test",
      authRequired: true,
    },
    component: () => import("../views/pages/test.vue"),
  },

  
];