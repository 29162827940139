export const state = {
    plate:'',
};

export const mutations = {
    setPlate(state, plate) {
        state.plate = plate;
    },
};

export const actions = {
    updatePlate({ commit }, plate) {
        commit('setPlate', plate);
    },    
};

export const getters = {
    getPlate: (state) => state.plate,
};
